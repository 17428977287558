<template>
  <div>
    <Header></Header>
    <div class="cpal1">
      <img class="cpalA1" src="@/assets/cpal.png" alt="" />
      <div class="cpalB1">产品案例 - 智慧煤矿</div>
      <div class="cpalC1">SMART COAL MINE</div>
    </div>
    <div class="body">
      <div>
        <div class="cpszh">智慧煤矿</div>
        <div class="bodyTitle">
          <div class="product">SMART COAL MINE</div>
          <!-- <div class="more">
            <div class="datetime">2022-10-23</div>
          </div> -->
        </div>
      </div>
      <div class="bodyContent">
        <div>
          <img class="cpalb1" src="@/assets/智慧煤矿.png" alt="" />
          <img class="cpalb1" src="@/assets/智慧煤矿2.png" alt="" />
        </div>
        <div class="fontA1">
          &emsp;&emsp;该项目主要由组态模块、数据采集模块、展示模块组成。实时采集设备温度数据，设备状态数据，实现设备异常状态报警，便于快速定位异常设备位置、快速处理故障。
        </div>
        <div class="fontA11">
          &emsp;&emsp;该项目实现电力集团公司基层单位项目立项、上级单位审批、回退、会签、审批流程查看等整个项目管理流程。规范了整电力集团公司项目审批流程、节省了项目从立项到结项各个环节的时间。
        </div>
      </div>
      <div class="LastNext">
        <div class="last" @click="twoL">上一案例：{{ last }}</div>
        <div class="next" @click="twoN">下一案例：{{ next }}</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      last: "智慧用电",
      next: "智慧执法运输",
    };
  },
  methods: {
    twoL() {
        this.$router.push({ name: "productCase1" });
    },
    twoN() {
        this.$router.push({ name: "productCase3" });
    }
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 40px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.bodyTitle {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.product {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.more {
  display: flex;
}
.datetime {
  font-size: 20px;
  color: #7d7d7d;
  letter-spacing: 1px;
}
.bodyContent {
  display: block;
  border-bottom: 1px solid #d8d8d8;
}
.cpalb1 {
    width: 100%;
  margin: 24px 32px;
}
.fontA1 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 0 32px;
  line-height: 40px;
}
.fontA11 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 46px 32px;
  line-height: 40px;
}
.LastNext {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  font-size: 20px;
  color: #7d7d7d;
  letter-spacing: 1px;
}
.last {
    cursor: pointer;
}
.next {
    cursor: pointer;
}
.cpal1 {
  position: relative;
}
.cpalA1 {
  width: 100%;
}
.cpalB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.cpalC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
</style>
